import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { media, theme } from '@dfds-ui/theme'
import { Headline } from '@dfds-ui/react-components'

import { FlexBox } from '../FlexBox'

const IconBlock = styled(FlexBox)`
  margin: 20px 0;
  flex: 0 0 100%;
  height: calc(100% - 40px);
  border: 0;
  padding: 0 20px;

  ${({ hasBorders }) =>
    hasBorders &&
    media.greaterThan('m')`
      &:not(:nth-child(1)):not(:nth-child(3)) {
        border-left: 1px solid ${theme.colors.divider.dark};
      }
    `}
  ${({ hasBorders }) =>
    hasBorders &&
    media.greaterThan('l')`
    &:not(:nth-child(1)):not(:nth-child(3)), &:nth-child(3) {
      border-left: 1px solid ${theme.colors.divider.dark};
    }
  `}

  ${media.greaterThan('m')`
    flex: 0 0 50%;
  `}

  ${media.greaterThan('l')`
    flex: 1;
  `}
`

const Icon = styled.img`
  max-width: ${({ iconWidth }) => `${iconWidth || '100'}px`};
  max-height: ${({ iconHeight }) => (iconHeight ? `${iconHeight}px` : 'auto')};
  width: ${({ iconWidth }) => iconWidth && `${iconWidth}px`};
  height: ${({ iconHeight }) => iconHeight && `${iconHeight}px`};
`

const IconBlockList = ({
  icons,
  iconBlocksCollection,
  hasBorders,
  noMarginBottom = false,
  iconWidth = 100,
  iconHeight = 100,
  title,
}) => {
  const iconsToUse = iconBlocksCollection.items
    ? iconBlocksCollection.items
    : icons
  return (
    <>
      {title && <Headline as={'h2'}>{title}</Headline>}
      <FlexBox
        itemsFlexStart
        wrapWrap
        itemsCenter
        justifySpaceAround
        css={css`
          background-color: ${theme.colors.surface.primary};
          padding: 20px;
          text-align: center;
          ${!noMarginBottom && 'margin-bottom: 40px;'}
        `}
      >
        {iconsToUse.map(({ title, icon, ComponentIcon }, index) => (
          <IconBlock
            hasBorders={hasBorders}
            noMarginBottom={noMarginBottom}
            itemsCenter
            directionColumn
            key={index}
          >
            {ComponentIcon || (
              <Icon
                src={`${icon.url}?w=${iconWidth || 100}`}
                iconWidth={iconWidth}
                iconHeight={iconHeight}
                alt={icon.description || title}
              />
            )}
            {title && <p>{title}</p>}
          </IconBlock>
        ))}
      </FlexBox>
    </>
  )
}

export const query = graphql`
  fragment IconBlockList on contentful_IconBlockList {
    __typename
    sys {
      id
    }
    title
    iconBlocksCollection(limit: 4) {
      items {
        title
        icon {
          title
          url
        }
      }
    }
  }
`

IconBlockList.defaultProps = {
  hasBorders: true,
  noMarginBottom: false,
  iconBlocksCollection: {},
  icons: [],
}

export default IconBlockList
